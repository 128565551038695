<template>
    <div class="orderCount">
        <!-- 订单统计 -->
        <h3>订单统计</h3>
        <div class="searchForm">
            <a-input placeholder="输入单号" v-model="searchForm.order_no" style="width:12vw;margin-right:10px;height:36px;" />
            <a-select style="width:12vw;margin-right:10px;" placeholder="店员" v-model="searchForm.order_clerk_id" allowClear v-if="$store.state.shop_info.is_salesman == 2">
                <a-select-option v-for="item in clerkList" :key="item.clerk_id" :value="item.clerk_id">{{item.real_name}}</a-select-option>
            </a-select>
            <a-range-picker 
                :placeholder="['开始时间' , '结束时间']" v-model="timeData"
                valueFormat="YYYY-MM-DD"
            >
                <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
            <a-select style="width:12vw;margin-right:15px;" v-model="searchForm.pay_type" placeholder="付款方式" allowClear>
                <a-select-option v-for="item in payTypeList" :key="item.id" :value="item.type_name">{{item.type_desc}}</a-select-option>
            </a-select>
            <!-- 筛选-支付状态，10 待结算 20 已结算 30 已结算挂账 60 已退款 -->
            <a-select style="width:12vw;margin-right:10px;" v-model="searchForm.pay_status" placeholder="支付状态" allowClear>
                <a-select-option value="10">待结算</a-select-option>
                <a-select-option value="20">已结算</a-select-option>
                <a-select-option value="30">已结算挂账</a-select-option>
                <a-select-option value="60">已退款</a-select-option>
            </a-select>
            <a-button class="searchBtn" @click="searchHandle">查询</a-button>
            <!-- <div class="btn" @click="excelDownload">导出</div> -->
        </div>
        <div class="tableBox">
            <a-table :columns="columns" :data-source="tableData" bordered @change="handlePageChange" rowKey="order_id" :locale="{emptyText: '暂无数据'}"
            :pagination="{
              current: searchForm.page,
              total: searchForm.total,
              pageSize: 10,
            }">
                <template slot="paylog" slot-scope="record">
                    <span v-for="(item,i) in record.paylog" :key="i">
                        <span>{{item.type_desc}}({{item.payed_price ? Number(item.payed_price).toFixed(2) : ''}})</span><br>
                    </span>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
import { requestUserClerkList , payType , orderStatistics , exportOrderStatistics } from '@/request/reportForm'

export default {
    name:'orderCount',
    data(){
        return{
            clerkList:[],
            payTypeList:[],
            timeData:[],
            columns:[],
            tableData:[],
            searchForm:{
                listRows:10,
                page:1,
                total:0
            },
            searchedForm:{
                listRows:10,
                page:1,
                total:0
            },
        }
    },
    mounted(){
        this.FOOD_TYPE = localStorage.getItem('FOOD_TYPE') || 'feast'
        if(this.FOOD_TYPE === 'feast'){
            this.columns = [
                {
                    title: '订单号',
                    dataIndex: 'order_no',
                    align:'center'
                },
                {
                    title: '桌号',
                    dataIndex: 'table_name',
                    align:'center'
                },
                {
                    title: '开台时间',
                    dataIndex: 'foundingTime',
                    align:'center'
                },
                {
                    title: '结算时间',
                    dataIndex: 'settlement_time',
                    align:'center'
                },
                {
                    title: '店员',
                    dataIndex: 'order_clerk_name',
                    align:'center'
                },
                {
                    title: '客户人数',
                    dataIndex: 'people',
                    align:'center'
                },
                {
                    title: '优惠金额',
                    dataIndex: 'discount_price',
                    align:'center'
                },
                {
                    title: '实收金额',
                    dataIndex: 'payed_price',
                    align:'center'
                },
                {
                    title: '付款方式',
                    key: 'paylog',
                    scopedSlots: { customRender: 'paylog' },
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'pay_status',
                    align:'center'
                },
            ];
        }else{
            this.columns = [
                {
                    title: '订单号',
                    dataIndex: 'order_no',
                    align:'center'
                },
                {
                    title: '开台时间',
                    dataIndex: 'foundingTime',
                    align:'center'
                },
                {
                    title: '结算时间',
                    dataIndex: 'settlement_time',
                    align:'center'
                },
                {
                    title: '店员',
                    dataIndex: 'order_clerk_name',
                    align:'center'
                },
                {
                    title: '客户人数',
                    dataIndex: 'people',
                    align:'center'
                },
                {
                    title: '优惠金额',
                    dataIndex: 'discount_price',
                    align:'center'
                },
                {
                    title: '实收金额',
                    dataIndex: 'payed_price',
                    align:'center'
                },
                {
                    title: '付款方式',
                    key: 'paylog',
                    scopedSlots: { customRender: 'paylog' },
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'pay_status',
                    align:'center'
                },
            ];
        }
        requestUserClerkList().then(res => {
            if(res.data.status === 200){
                this.clerkList = res.data.data.list
            }
        })
        payType().then(res => {
            if(res.data.status === 200){
                this.payTypeList = res.data.data
            }
        })
        this.getTableData()
        
        if(this.$store.state.shop_info.is_salesman == 1){
            let i = this.columns.findIndex(item => item.title == '店员')
            if(i > -1){
                this.columns.splice(i,1)
            }
        }
        var datetime = new Date();
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var date = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate();
        this.searchForm.start_time=this.getThreeDaysAgo()
        this.searchForm.end_time=`${year}-${month}-${date}`
        this.timeData=[ this.searchForm.start_time,this.searchForm.end_time]
        this.searchedForm.start_timeSure=this.start_time//最终开始时间
        this.searchedForm.end_timeSure=this.end_time//最终结算时间
        console.log()
    },
    methods:{
        getTableData(){
            if(this.timeData && this.timeData.length === 2){
                this.searchForm.start_time = this.timeData[0]
                this.searchForm.end_time = this.timeData[1]
            }else{
               this.searchForm.start_time = ''
                this.searchForm.end_time = ''
            }
            orderStatistics(this.searchForm).then(res => {
                if(res.data.status === 200){
                    this.tableData = res.data.data.list.data
                    this.searchForm.total = res.data.data.list.total
                }
            })
        },
        searchHandle(){
            
            this.searchForm.page = 1
            this.searchedForm = JSON.parse(JSON.stringify(this.searchForm))
            this.getTableData();
        },
        handlePageChange(e) {
            this.searchForm = JSON.parse(JSON.stringify(this.searchedForm))
            this.searchForm.page = e.current;
            this.getTableData();
        },
        excelDownload(){
            exportOrderStatistics(this.searchForm).then(res => {
                if (res.data) {
                    const xlsx = 'application/csv,charset=UTF-8'
                    let data = '\ufeff' + res.data;
                    const blob = new Blob([data] , {type: xlsx})
                    const a = document.createElement('a')
                    a.download = `订单统计.csv`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            })
        },
        getThreeDaysAgo() {// 获取三十天之前的日期
            let myDate = new Date();
            let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30); //最后一个数字30可改，30天的意思
            let lastY = lw.getFullYear();
            let lastM = lw.getMonth() + 1;
            let lastD = lw.getDate();
            let startData =
                lastY +
                "-" +
                (lastM < 10 ? "0" + lastM : lastM) +
                "-" +
                (lastD < 10 ? "0" + lastD : lastD); //三十天之前日期
            return startData;
            },
        }
}
</script>
<style lang="less" scoped>
.orderCount{
    background: #f8f8ff;
    width: 100%;
    height: 100%;
    h3{
        padding: 2vh 1vh 0 1vh;
        font-weight: 700;
        color: #666;
        height: 5%;
        box-sizing: border-box;
    }
    .searchForm{
        padding: 1vh 1vh 0 1vh;
        display: flex;
    }
    .tableBox{
        margin: 5px;
        height: calc(100% - 14vh);
        width:100%;
        overflow-x: auto;
        width:82.5vw;
        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
    }
}
::v-deep .ant-input{
    height: 3.9vh !important;
}
::v-deep .ant-select-selection--single{
    height: 3.9vh ;
}
::v-deep .ant-select-selection__rendered{
    line-height: 34px;
}

// /deep/.ant-pagination {
//   position: fixed;
//   right: 2vh;
//   bottom: 1vh;
// }

/deep/.ant-calendar-picker-input{
   font-size: 1.8vh;
   height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
/deep/th,/deep/td{
  font-size: 1.8vh;  
  padding:1vw 1vw !important;
}

</style>