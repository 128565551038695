import { render, staticRenderFns } from "./orderCount.vue?vue&type=template&id=21b550ba&scoped=true&"
import script from "./orderCount.vue?vue&type=script&lang=js&"
export * from "./orderCount.vue?vue&type=script&lang=js&"
import style0 from "./orderCount.vue?vue&type=style&index=0&id=21b550ba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b550ba",
  null
  
)

export default component.exports